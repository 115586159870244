<template>
  <div class="single-content">
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.9994 17.4201V20.4201C22.0006 20.6986 21.9435 20.9743 21.832 21.2294C21.7204 21.4846 21.5567 21.7137 21.3515 21.902C21.1463 22.0902 20.904 22.2336 20.6402 22.3228C20.3764 22.412 20.0968 22.4452 19.8194 22.4201C16.7423 22.0857 13.7864 21.0342 11.1894 19.3501C8.77327 17.8148 6.72478 15.7663 5.18945 13.3501C3.49942 10.7413 2.44769 7.77109 2.11944 4.6801C2.09446 4.40356 2.12732 4.12486 2.21595 3.86172C2.30457 3.59859 2.44702 3.35679 2.63421 3.15172C2.82141 2.94665 3.04925 2.78281 3.30324 2.67062C3.55722 2.55843 3.83179 2.50036 4.10945 2.5001H7.10945C7.59475 2.49532 8.06524 2.66718 8.43321 2.98363C8.80118 3.30008 9.04152 3.73954 9.10944 4.2201C9.23607 5.18016 9.47089 6.12282 9.80945 7.0301C9.94399 7.38802 9.97311 7.77701 9.89335 8.15098C9.8136 8.52494 9.62831 8.86821 9.35944 9.1401L8.08945 10.4101C9.513 12.9136 11.5859 14.9865 14.0894 16.4101L15.3594 15.1401C15.6313 14.8712 15.9746 14.6859 16.3486 14.6062C16.7225 14.5264 17.1115 14.5556 17.4694 14.6901C18.3767 15.0286 19.3194 15.2635 20.2794 15.3901C20.7652 15.4586 21.2088 15.7033 21.526 16.0776C21.8431 16.4519 22.0116 16.9297 21.9994 17.4201Z"
        stroke="#FFCA42"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>

    <input type="text" placeholder="Number" />
  </div>
</template>

<style lang="scss">
.single-content {
  display: flex;
  align-items: center;
  background: #ffffff;
  width: 100%;
  border: solid 1px #dce1e9;

  svg {
    padding: 10px;
  }

  input {
    border: none;
    width: 100%;
    height: 100%;
    outline: none;
    font-family: "Inter", sans-serif;
    font-size: 19px;
    color: #969aa0;
    font-weight: 500px;
  }
}
</style>
